#qg-site-name {
// background-color:rgba(234, 234, 234, 0.49);

@extend .col-lg-12; 
@extend .col-md-12;
@extend .col-sm-12;
@extend .col-xs-12;

background-color: #009dff;
h2 {
margin: .3em 0;
}
}
