.qg-inner {
 background-color: red;
    border: solid 2px #F00;
}
.qg-hidden-sm-xs {
  @extend .hidden-sm;
  @extend .hidden-xs;
}
.qg-sm-img {
  @extend .hidden-md;
  @extend .hidden-lg;
}
.qg-hidden-md-lg{
  @extend .hidden-md;
  @extend .hidden-lg;
}

.qg-visually-hidden{
  @include qg-visually-hidden;
}
@include breakpoint($bs-md) {
  .qg-visually-hidden-md {
    @include qg-visually-hidden;
  }
}