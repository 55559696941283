@function em($target, $context: $base-font-size) {
  @if $target == 0 { @return 0 }
  @return $target / $context + 0em;
}
@function calculateRem($size) {
  $remSize: $size / $baseSize;
  @return #{$remSize}rem;
}
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
@mixin indent($num){
  @for $i from 1 through $num {
    &:nth-child(#{$i+1}){
      margin-left: #{$i*5}px;
    }
  }
}

@mixin qg-visually-hidden {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  *{border: 0;}
}