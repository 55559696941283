  .qg-coat-of-arms {
    @extend .col-xs-12;
    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .col-lg-4;
    padding-bottom: .8em; 
    overflow: hidden;
      
    img {
  //    width: 287px; //current width of logo
      height: 50px;
    }
      
    // Responsive styles  
    @include breakpoint($bs-xs) {
    img {
      height: 40px;
    }
}  
      
      
  }


