/*
 * Row with equal height columns

 * See: http://getbootstrap.com.vn/examples/equal-height-columns/
 * Usage: add class .row-eq-height to row

 * --------------------------------------------------
 */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

/*
 * Styles copied from the Grid example to make grid rows & columns visible.
 */
//.container {
//  padding-right: 15px;
//  padding-left: 15px;
//}
//
//h4 {
//  margin-top: 25px;
//}
//.row {
//  margin-bottom: 20px;
//}
//.row .row {
//  margin-top: 10px;
//  margin-bottom: 0;
//}
//[class*="col-"] {
//  padding-top: 15px;
//  padding-bottom: 15px;
//  background-color: #eee;
//  background-color: rgba(86,61,124,.15);
//  border: 1px solid #ddd;
//  border: 1px solid rgba(86,61,124,.2);
//}

/*
 * Callout styles copied from Bootstrap's main docs.
 */
/* Common styles for all types */
//.bs-callout {
//  padding: 20px;
//  margin: 20px 0;
//  border-left: 3px solid #eee;
//}
//.bs-callout h4 {
//  margin-top: 0;
//  margin-bottom: 5px;
//}
//.bs-callout p:last-child {
//  margin-bottom: 0;
//}
//.bs-callout code {
//  background-color: #fff;
//  border-radius: 3px;
//}
///* Variations */
//.bs-callout-danger {
//  background-color: #fdf7f7;
//  border-color: #d9534f;
//}
//.bs-callout-danger h4 {
//  color: #d9534f;
//}
//.bs-callout-warning {
//  background-color: #fcf8f2;
//  border-color: #f0ad4e;
//}
//.bs-callout-warning h4 {
//  color: #f0ad4e;
//}
//.bs-callout-info {
//  background-color: #f4f8fa;
//  border-color: #5bc0de;
//}
//.bs-callout-info h4 {
//  color: #5bc0de;
//}
