#qg-primary-content #qg-index-links {
position: relative;
margin-bottom: 2em;
@extend .clearfix; 
@extend .row; 

div {
position: relative;
padding-bottom: 1em;
    
h2 {
color: #13578b;      
@include fontSize(19px);
letter-spacing: .05em;

a {
color: #13578b;
transition: all 0.2s;
}

a:hover {
text-decoration: none;
border-bottom: 1px solid #1f76b9;    
}   

//a:after {
//font-family: FontAwesome;
//font-weight: normal;
//display: inline-block !important;
//content: "\f0a9";
//color: #1f76b9;      
//margin: 0 0 0 0.5em;
//text-decoration: none;
//}   

} 

img {   
float: left; 
max-width: 100%;
height: auto;
}

p, ol, ul {
@include fontSize(16px);
padding: 0;
margin: 0;
}

img + ul, img + ol, img + p  {
margin-left: 95px;    
}   

li { 
list-style-type: none; 
padding: 0;
margin: 0 0 0.5em 0;

a {
text-decoration: none;  
}

a:hover {
text-decoration:underline;  
}  

}

li:before {
font-family: FontAwesome;
font-weight: normal;
font-size: .9em;
display: inline-block !important;
content: "\f054";
margin: 0 0.3em 0 0;
color: #3384c1;
}  

}

  
}

/* ! content section links for index pages - 2 column */

#qg-two-col-aside #qg-index-links div {
position: relative;   
@extend .col-sm-6;
@extend .col-md-6;
@extend .col-lg-6;   
}


/* ! content section links for index pages - 3 column */

#qg-three-col #qg-index-links div {
position: relative;   
@extend .col-sm-6;
@extend .col-md-12;
@extend .col-lg-12;
    
    img {
    float:none;
    max-width: 100%; 
    }  
    
}