// ############################################
// Site navigation      
#qg-site-nav {  
    @extend .col-xs-12;
    @extend .col-sm-12;
    @extend .col-md-12;
    @extend .col-lg-12;    
    background-color: #F6F6F6;
    padding: 0;

    ul {
        color: #005375;
        @include fontSize(16px);
        bottom: 0px;

        li {
            display: block;
            float: left;
            padding: 0;

            a {
                display: inline-block;
                padding: .3em .5em .7em .5em;
                margin: 0;
                color: #005375;
                text-decoration: none;    
                @include fontSize(16px);
                &:hover {
                    background-color: #e0e0e0;
                    text-decoration: none;
                }
            }    
        }

        li + li {
//border-left: 1px solid #666;
}

}
}

// ############################################
// responsive styles
@include breakpoint($bs-md) {

    #qg-site-nav {
        background: #494949;

        ul {
            margin: 0;
            padding: 0;

            li {
                float: none;

                &:first-child {} 

                &:last-child {
                    border-bottom: none
                }

                &:nth-child(2n) {
                    background-color: #505050;
                }

                a, a:link, a:visited {
                    color: #FFF;  
                    &:hover {
                        background-color: transparent;
                        text-decoration: underline;
                    }   

                }  

            }

            li + li {
                border: none;      
            }
        }
    }

}

@include breakpoint($bs-sm) {
}

// ############################################
// responsive styles
@include breakpoint($bs-xs){
}

@include breakpoint($bs-min-lg){
    #qg-site-nav.collapse{ // Over-rides SWE default behaviour for accessibility
       display: block;
       height: auto !important;
    }
}