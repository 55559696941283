/* ! alert style */

header .alert {
margin-top: 0;    
margin-bottom: 0;
@extend .col-lg-12;   
border: none;  


time {
display: inline-block;
margin-left: 1em; 
font-size: .8em; 
}    
}

.alert {
border-radius: 0;  

}

.alert-danger {
position: relative; 
//background-color: #bf2a2a;    
background: #bf2a2a;
background: -moz-linear-gradient(top,  #bf2a2a 70%, #b11616 100%);
background: -webkit-linear-gradient(top,  #bf2a2a 70%,#b11616 100%);
background: linear-gradient(to bottom,  #bf2a2a 70%,#b11616 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf2a2a', endColorstr='#992323',GradientType=0 );

a, a:link {
color: #FFF;  
}   

.fa-exclamation-triangle {
position: absolute;
top: 10px;
left: 10px;   
font-size: 3em;
color:#FFF;
}  

h2 {
color: #FFF;
margin: 0; 
padding: 0 0 0.3em 0; 
font-size: 1.2em; 
}

h2, p, ol, ul {
color: #FFF;
margin-left: 85px;   
}  

}
