
.qg-more-info {
color: #FFF;
background-color:#0B2E4C;
padding-bottom: 0;
a, a:link {
color: #FFF;  
text-decoration: none;
} 

a:hover {
color: #FFF;  
text-decoration: underline;
} 

div {
@extend .col-lg-4;    
@extend .col-md-4;
  
&:nth-child(2n) {
background-color: #0b3253;
} 

h3 {
@include fontSize(19px);
border-bottom: 1px solid #FFF; 
padding-top: 1em;
padding-bottom: .5em; 
}  

ul {
list-style: none;
padding-left: 0;
} 
    
ul.col-2 {
list-style: none;
padding-left: 0;
column-count: 2;
}  
    
li {
@include fontSize(14px);
padding: .5em .3em;
}

}

}

// ###########################################

.qg-legal{
text-align: center;
@include fontSize(14px);

.row {
background-color: #FFF;     
}  

ul {
margin-top: 1em;  


li {
 padding: .3em .5em;
 margin: 0;      
}

li + li {
border-left: 1px solid #999; 
}   
}

p {
//@include fontSize(12px);
}
}


@include breakpoint($bs-md){
ul.col-2 {
list-style: none;
padding-left: 0;
column-count: 1 !important;
}   
    
    
/*

#qg-site-footer {
background: #0B2E4C;
}
*/

}
