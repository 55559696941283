
/* ! asides */
#qg-content #qg-secondary-content {
margin: 0;
padding: 0;  
   
    h2 {
    margin: 0;
    padding: 0;     
    }

        .qg-aside {
        background-color: #f2F7F9;      
        padding: 1.5em 1em;
        margin: 0;

            .fa {
            // color: #78BA00;
            font-size: 120%;
            margin: 0 0.3em 0 0;
            }

            h2 {
            font-weight: 700;
            margin: 0 0 1em 0; 
            }

            figure {   
            margin: 0;
            padding: 0; 
            position:relative; 
            text-align: center;

                img {
                margin: 0;
                padding: 0; 
                //width: 100%;
                max-width: 100%;
                height: auto;   
                }

                figcaption {
                margin: 0;
                padding: .5em; 
                font-size: 90%;
                font-style: italic;
                text-align: center;       
                }

            }

        } 

    // possible background colors for asides:
    // background-color: #F2F7F9;
    // background-color: #F7FBFF;
    // background-color: #EEF4F6;
    // background-color: #F2F7F9;
    // background-color: #E7F5FE;
    // background-color: #E1ECF1;

    .qg-aside:nth-child(2n) {
    background-color: #E7F5FE;
    }

    .qg-aside:nth-child(3n) {
    background-color: #E1ECF1;
    }

    .qg-aside h2 span:before {
    color: #78BA00;
    }

    .qg-search {}

    .qg-subscribe {}

    .qg-contact {}

    .qg-disaster {}

    .qg-contact {}

    .qg-emergency-contact {}

    .qg-your-say {}

    .qg-tip {}

    .qg-story {}

    .qg-feedback {}
    
   
@include breakpoint($bs-xs){
}
    

}