/*

// ##############################################################    
// Breadcrumb (Mobile First)

#qg-breadcrumb {
    @extend .col-sm-12;
    background: $sweblue;

    a {
        color: #FFF;
        text-decoration: none;

        &:hover { text-decoration: underline; }
    }

    span.fa {
        font-size: 1.2em;
        margin-right: .3em;
    }

    ol {
        margin: 0;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        color: #FFF;
        text-align: center;
        @include fontSize(16px);

        > li {
            display: block;
            margin: 0;

            + li:before {
                content: "\f078";    
                display: block;

                margin: 0;

                color: #CCC;       
                font-family: FontAwesome;
                font-size: .9em;
                font-weight: normal;
            }  

            &:last-child {
                font-size: 150%;
                font-weight: bold;
            } 
        }
    }
}
@include breakpoint($bs-sm){
    #qg-breadcrumb{
        padding-left: 0;
        padding-right: 0;
    }
}
@include breakpoint($bs-min-lg){
    #qg-breadcrumb.collapse{ // Over-rides SWE default behaviour for accessibility
       display: block;
       height: auto !important;
    }
    #qg-breadcrumb ol {
        text-align: left;

        > li{
            display: inline-block;
            margin: auto;

            + li:before {
                content: "\f054";
                display: inline-block;
                margin: 0 0.3em 0 0;
                color: #FFF;
            }

            &:last-child{
                font-size: 100%;
            }
        }
    }
}

*/

// ##############################################################    
// Breadcrumb (Desktop first)

#qg-breadcrumb {
    @extend .col-sm-12;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    background: $sweblue;

    a {
        color: #FFF;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }

    span.fa {
        font-size: 1.2em;   
        margin-right: .3em; 
    }    

    ol {
        margin: 0;
        color: #FFF;    
        @include fontSize(16px); 

        li + li {
            &:before {
                color: #FFF;
                font-family: FontAwesome;
                font-size: .9em;
                content: "\f054";
                margin: 0 0.3em 0 0;
            }

            &:last-child{
                font-weight: bold;
            }
        }

    }
}

// ##############################################################    

@include breakpoint($bs-md){
    #qg-breadcrumb{
        ol {
            text-align: center;
            margin-left: 0;
            margin-bottom: 0;

            > li {
                display: block;
                // @include indent(4)

                + li:before {
                    //display: inline-block;        
                    display: block;
                    color: #CCC;          
                    font-family: FontAwesome;
                    font-size: .9em;
                        text-align: center;
                    content: "\f078";
                    //content: "\f149"; 
                    //margin-right: .5em;
                    font-weight: normal;
                    margin: 0;
                }  

                &:last-child {
                    font-size: 150%;
                } 
            }
        }
    }
}

@include breakpoint($bs-sm){
    padding-left: 0;
    padding-right: 0;
}

@include breakpoint($bs-min-lg){
    #qg-breadcrumb.collapse{
       display: block;
       height: auto !important;
    }
}

