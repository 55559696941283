/* Work in progress stylesheet
- these style will be incorporated into the appropriate SASS files
- some of these styles will use existing mixins

*/


#qg-search-form-content-container {
text-align: center; 
margin-top: 20px; 
margin-bottom: 50px; 
}

#qg-search-form-content {
@extend .col-md-6;
@extend .col-lg-6; 
float: none;
margin: 0 auto;

input {
border-radius: 0;
}

button.btn-default {
background: $green;
border-radius: 0;
}

}



#qg-site-footer-cue {
color: #404040;
background-color:#fcfcfc;

a, a:link {
color: #555555;  
text-decoration: none;
} 

a:hover {
color: #2e2e2e;  
text-decoration: underline;
} 

div {
@extend .col-md-5;
@extend .col-sm-3;
background-color:#fcfcfc;   

&:nth-child(2n) {
background-color: #f5f5f5;
} 
}

ul {
list-style: none;
padding-left: 0;
}
}

.qg-legal{
text-align: center;

.row {
background-color: #FFF;     
}  

p {
@include fontSize(12px);
}
}

/* ! BIP content section links for index pages */

.index-page-grid {
position: relative;
margin-bottom: 2em;
position: relative;         
@extend .clearfix;
padding: 15px 0;
 margin: 15px;
    
div {
position: relative;   
@extend .col-sm-6;
@extend .col-md-4;
@extend .col-lg-4;
} 

div.style-1 {
margin: 0 15px;  
background-color: #FFF;
 
figure {
width: 100%;
margin-top: 15px;
   
img {
width: 100%;
height: auto;
padding: 0;
margin: 0;
}    

}  

h2 {
color: #13578b;      
@include fontSize(19px);
letter-spacing: .05em;

a {
color: #13578b;
transition: all 0.2s;
}

a:hover {
text-decoration: none;
border-bottom: 1px solid #1f76b9;    
}   

} 

} 

div.style-3 {   
margin: 0 15px;  
border: 1px solid #FFEA8A; 
background-color: #FFF;
border-width: 1px 1px 1px 10px;  
}

div.style-3:nth-child(2n) {
border: 1px solid #56B7C5; 
border-width: 1px 1px 1px 10px;  
}

div.style-3:nth-child(3n) {
border: 1px solid #de8787;  
border-width: 1px 1px 1px 10px;    
} 

}

.index-page-grid-1 {
@extend .index-page-grid;
background-color: aliceblue;
    
}

/* ! end content section links for index pages */




