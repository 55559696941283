/* ! section (side) navigation */
#qg-content #qg-section-nav {   
background-color: #F6F6F6 !important;
padding: 0;
        
h2 {
@include fontSize(19px);
padding: 2em 0 1.2em 30px;
margin:  0;
color: #13578b;
} 

ul {
background-color: #F6F6F6; 
margin: 0;
padding: 0;
}

li {
padding:0;
list-style-type: none;
@include fontSize(16px);
font-weight: 400;
transition: all 0.2s;

a, a:link, a:visited {
margin: 0;
padding: .5em .5em .3em 30px;
text-decoration: none;
display: block;
}

a:hover {
text-decoration:none;
background-color: #d1d1d1;
}

a.active {
color: #FFF;
background-color: #007EB1;     
}

a:before {
font-family: FontAwesome;
font-size: .9em;
display: inline-block !important;
content: "\f054";
margin: 0;
padding: 0 0.3em 0 0;
color: #13578b;
text-decoration: none;
opacity: .5;
}
    
a.active:before {
color: #FFF;  
 opacity: 1;   
}    
           
li a:before  {
padding-left: 30px;  
}
        
}
   
}