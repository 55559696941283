#qg-feedback {
    background-color:#007EB1;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.2em;

    a, a:link {
        color: #FFF;
    }

    a:hover {
        color: #FFF;
        text-decoration: underline;
    }

    #page-feedback-useful {
        background-color: #005375;
        color: #FFF;
        border: #0B2E4C;
        transition: all .2s;

        &:hover {
            background-color: #0B2E4C;
        }

    }

    h2 {
        display: inline;
        margin: 0;
        padding: 0;
        color: #FFF;
    }

    .fa {
        font-size: 1.2em;
    }

    .qg-cta, .qg-share {
        @extend .col-md-6;
        @extend .col-sm-6;

        ul {
            margin: 0;
            padding:0;
            display: inline-block;
            text-align: right;
            color: #FFF;
            li {
                display: inline;
                margin-left: .5em;
            }
        }
    }

    .qg-cta {
        @extend .col-md-pull-6;
        @extend .col-sm-pull-6;
    }

    .qg-share {
        @extend .col-md-push-6;
        @extend .col-sm-push-6;
        text-align: right;
    }

}

// #################################################
// Feedback form

#qg-page-feedback {
    //display: none;
    background-color: #f8f8f8;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #000;
    @include fontSize(16px);

    #feedback-captcha-container {
        display:none;
    }
    .panel {
        background-color: transparent;
    }
    .warn {
        padding: 1em;
        @extend .col-lg-12;
        background-color: #FFEDDE;
    }
          
    .info {
        padding: 1em;
        margin-bottom: 1em;
        background-color: #EEF4F6;
    }

    .required {
        color: #c90000;
    }

    .invalid {
        color: #c90000;

    label {
        color: #c90000;
    }

    input {
        border: 2px solid red;
    }

    textarea {
        border: 2px solid red;
    }

} 

.alert {
    display: inline;
    color: #c90000;
    @include fontSize(16px);
} 

// ################################################# 
// #################################################  

#qg-page-feedback-form {
    @extend .col-md-12;
    @include breakpoint($bs-sm){
        margin:0;
        padding: 0;

        h2, p {
            margin: 1em 35px;
        }

        textarea {
            width: 100%;
        }
    }
}

form.form {

    ol, ol.questions, ul, ul.questions {
        margin: 0;
        padding: 1em 0;
        list-style-type: none;
    }

    ul.actions li {
        display: inline-block;
        margin-right: 1em;
        text-align: center;
    }

    .label {
        color: #000 !important;
        font-weight: normal;
        @include fontSize(16px);
        display: inline;
        position: static;
        width:auto;
        max-width: auto;
        margin: 0;
        padding:0;
    }

    fieldset {
        color: #000;
        margin: 0;
        padding:0;

        legend {
            color: #000;
            margin: 0;
            padding:0;
            border-bottom: none;
        }

    }

    label {
        font-weight: normal;
        font-size: 1em;
        color: #000;
        display: inline;
        position: static;
        width:auto;
        max-width: auto;
        margin: 0;
        padding:0;
    }

    input {
        display: inline;
        position: static;
        width:auto;
        max-width: auto;
        margin: 0;
        padding:0;
    }

    input[type="radio"] {}

    #comments {
        display: block;
    }

  }

}

// #################################################
// #################################################

@include breakpoint($bs-md) {}

@include breakpoint($bs-sm){
    
    #qg-feedback {
        .qg-share  {
        text-align: center;
        }
        .qg-cta  {
        text-align: center;
        margin-top: 1em;
        }
    }

    #qg-page-feedback {
        .info {
            padding: 1em 0 !important;
        }
    }
}
