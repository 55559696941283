//font family
$font-main: Lato, Arial, sans-serif;

// base font size
$baseSize: 16px;
$baseLineHeight: 1.4;

//base-padding
$base-padding: 1em;

// colours
$lightgrey: rgba(238, 241, 245, 0.17);
$green: #78BA00;
$grey: #f6f6f6;
$linkcolour: #005375;
$sweblue: #007EB1;
$swedarkblue: #005375;

// responsive (Bootstrap default)
$bs-xs: max-width 480px;
$bs-sm: max-width 768px;
$bs-md: max-width 992px;
$bs-lg: max-width 1200px;

$bs-min-sm: min-width 480px;
$bs-min-md: min-width 768px;
$bs-min-lg: min-width 992px;

// Set the gutter width
$grid-gutter-width: 40px;

// Make buttons square cornered
//$btn-border-radius-base: 0px;
//$btn-border-radius-large: 0px;
//$btn-border-radius-small: 0px;

//Setting 16 column grid
//$new-columns:16;
//$grid-columns:$new-columns;

//$new-columns :                  12;
//$new-gutter-width :             40px;
//
//$grid-columns :                 $new-columns;
//$grid-gutter-width :            $new-gutter-width;