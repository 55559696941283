
#qg-site-header {
  background-color: #f6f6f6;
  @extend .row;
  margin-top: 32px;
  padding-top: 1em;
  padding-bottom: 0;


  // Responsive styles
    @include breakpoint($bs-sm) {
      margin-top: 0;
    }
       
}

